<script>
	export let theme = "cupcake";
</script>

<div data-theme={theme} class="flex flex-col grow">

	{#if $$slots.title}

		<div class="bg-primary">
			<div class="container mx-auto p-4 max-w-5xl text-primary-content">
				<slot name="title" />
			</div>
		</div>

	{/if}

	{#if $$slots.hero}

		<div class="bg-primary">
			<div class="container mx-auto p-4 max-w-5xl text-primary-content">
				<div class="flex flex-col items-start justify-center gap-6 py-12">
					<slot name="hero" />
				</div>
			</div>
		</div>	

	{/if}

	<div class="container mx-auto p-4 max-w-5xl bg-base-100 grow">
		<slot />
	</div>
</div>